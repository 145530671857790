.existing-location {
	margin-top: 10px;
	padding: 15px;
	border: 1px solid #6057cc;
	border-radius: 10px;
}

.location-title{
	font-weight: 500;
    font-size: 14px;
}
.address {
    font-size: 12px;
}

.selected-location {
	margin-top: 10px;
	padding: 15px;
	border: 1px solid #6057cc;
	border-radius: 10px;
    background-color: #8176F5 ;
}
.new-location-button {
	float: right;
	width: auto !important;
	margin: 0 !important;
	padding: 10px !important;
	margin-top: 12px !important;
}  