.outlet-card {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.address-name {
  font-weight: 600;
}
.format-address {
  font-weight: 300;
}
