.success-3-border {
  border: 3px solid #28c76f !important;
}

.disabled-3-border {
  border: 3px solid #ffffff55 !important;
}

.success-2-border {
  border: 2px solid #28c76f !important;
}

.disabled-2-border {
  border: 2px solid #ffffff55 !important;
}

.custom-padding {
  padding: calc(0.5rem - 1px) !important;
  padding-left: calc(0.9rem - 1px) !important;
  padding-right: calc(0.9rem - 1px) !important;
}
.custom-padding-btn {
  padding: calc(0.5rem - 1px) !important;
}
