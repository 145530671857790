@import '../node_modules/prismjs/themes/prism-tomorrow';
// Overrides user variable
@import './assets/scss/app.scss';

iframe {
  z-index: -1 !important;
}
.swal2-container {
  z-index: 1500 !important;
  color: #ffffff;
  .swal2-title {
    color: #c2c6d6;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    .swal2-actions {
      button {
        outline: unset;
      }
    }
  }
  .swal2-deny {
    background-color: #c2c6dc !important;
    color: #10163a !important;
  }
  .swal2-popup {
    background: #262c49 !important;
  }
}
.swal2-html-container {
  color: #c2c6d6 !important;
}
.content-wrapper-pos {
  padding: 0 !important;
  margin-top: 64px !important;
  @media (max-width: 575px) {
    margin-top: 0px !important;
  }
  @media (max-width: 460px) {
    column-gap: 14px;
  }
  @media (max-width: 448px) {
    margin-top: 0px !important;
  }
  @media (max-width: 436px) {
    margin-top: 0px !important;
  }
  @media (max-width: 375px) {
    margin-top: 0px !important;
  }
  @media (max-width: 320px) {
    margin-top: 0px !important;
  }
  //height: calc(100vh - 13rem);
}
/* -- dialog -- */
.rodal-dialog {
  height: 100% !important;
  padding: 0 !important;
  /* position: relative; */
  background: transparent !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 80% !important;
  box-shadow: none;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
/* -- close button -- */
.rodal-close {
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  display: none;
}
.invoice-parent {
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  .invoice {
    margin-top: 20px;
    width: 80mm;
    max-height: fit-content;
    @media print {
      width: 90%;
      span {
        font-size: 12mm;
      }
      max-height: unset;
    }
  }
}

//Dropdown
.icon-set-dropdown-show {
  display: flex !important;
}
.icon-set-dropdown-hide {
  display: none;
}
.icon-active {
  background: #262c49;
  border-radius: 3px;
}
.custom-dropdown {
  &__input-group {
    position: relative;
    &__form {
      background: #10163a !important;
      border-radius: 5px;
      padding: 0.7rem 0.7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      input {
        width: 50%;
        background: #10163a !important;
        border: none;
        outline: none;
        color: #ffffff;
        font-family: 'Montserrat', Helvetica, Arial, serif !important;

        &::placeholder {
          color: #ffffff;
        }
      }
      h2 {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        font-family: 'Montserrat', Helvetica, Arial, serif !important;
        //color: #6d6f79 !important;
        display: flex;
        align-items: center;
        column-gap: 20px;
        color: #c2c6dc;
      }
    }

    &__dropdown {
      //display: none;
      transition: 0.5s;
      position: absolute;
      width: 100%;
      //display: flex;
      column-gap: 20px;
      flex-wrap: wrap;
      background: #10163a !important;
      border-radius: 5px;
      left: 0;
      top: 42px;
      padding: 20px 26px;
      row-gap: 20px;
      z-index: 250;
      cursor: default !important;
      height: 126px;
      overflow-y: scroll;

      @media (max-width: 575px) {
        column-gap: 16px;
      }
      @media (max-width: 460px) {
        column-gap: 14px;
      }
      @media (max-width: 448px) {
        column-gap: 12px;
      }
      @media (max-width: 436px) {
        column-gap: 32px;
      }
      @media (max-width: 375px) {
        column-gap: 20px;
      }
      @media (max-width: 320px) {
        column-gap: 24px;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #343d69;
        width: 4px;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #7367f0;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
      }
    }

    &__inputs {
      &__input {
        &__input_wrapper {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 34px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.preview-hide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.preview-show {
  display: block;
  visibility: show;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}
.perview-iframe {
  z-index: 1 !important;
  border: 0px !important;
}
.container-frame {
  width: 100%;
  height: calc(1000px * 0.55);
  position: relative;
}

.frame {
  z-index: 1 !important;

  width: 1777px;
  height: 1000px;
  transform: scale(0.55);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
}
.no-padding {
  padding: 0 !important;
}
header {
  background-color: #10163a !important;
}
#backgroundOverlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 1;
}
.Required {
  color: #d0312d !important;
}
.required-error {
  color: #d0312d !important;
  font-size: 14px;
}
.modular-label-font {
  font-size: 12px;
}
.content-location-pos {
  margin-top: -63px;
  padding: 20px;
  height: 100vh;

  @media (max-width: 450px) {
    margin-top: 0px !important;
  }

  //height: calc(100vh - 13rem);
}
.offline-modal-content {
  min-width: 1000px;
  @media (max-width: 575px) {
    min-width: 0px !important;
  }
}
.offline-modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 25px;
  justify-content: space-between;
}
.tox-edit-area {
  z-index: 1;
}
.scroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.scroller::-webkit-scrollbar-thumb {
  background: #7367f0;
  border-radius: 5px;
}
.scroller::-webkit-scrollbar-track {
  background: #343d69;
  width: 4px;
  border-radius: 5px;
}
