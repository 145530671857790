@import './bootstrap.scss';
@import './components.scss';
@import './bootstrap-extended.scss';
@import './colors.scss';
@import './iconfont.scss';
@import './custom-rtl.scss';
@import './../css/icon-sets.scss';

.printer-section-header {
  padding: 10px;
  background-color: #212744;
  color: white;
  margin-bottom: 10px;
}

.font-large-1 {
  font-size: 2rem;
}

.filter-container-left {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  background-color: #262c49;
  color: white;
  z-index: 9999;
}
.terms {
  overflow: auto;
  height: 600px;
  user-select: none;
}

.background-transparent {
  background: transparent !important;
}

.background-none {
  background: none !important;
}

.map-box {
  width: 100%;
  height: 400px;
  min-height: 400px;
  max-height: 600px;
}

.card-border-delivery {
  border: #262c49 2px solid !important;
}
.delivery-sub-area {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) calc(0.5rem - 1px) calc(0.5rem - 1px);
  border-color: #414561 !important;
  border: #414561 2px solid !important;
}
.card-header:first-child {
  border-radius: calc(0.5rem - 1px) !important;
}
.terms {
  overflow: auto;
  height: 600px;
  user-select: none;
}
.filter-container-left {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  background-color: #262c49;
  color: white;
  z-index: 9999;
}

.btn.btn-icon.btn-small {
  padding: 0.3rem 0.4rem !important;
  margin-left: 5px;
}

.timezone-picker-list-rel {
  position: absolute;
  background-color: #fff;
  z-index: 100;
  max-height: 130px !important;
}
.main-menu {
  z-index: 1000 !important;
}
html body .content .content-wrapper {
  padding-bottom: 200px !important;
}
.tooltip-settings {
  float: right;
  padding-bottom: 5px;
}
.tooltip-desc {
  position: relative;
  display: block;
  margin-left: -20px;
  text-align: left;
}
