@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?wqtpdz');
  src:  url('../fonts/icomoon/icomoon.eot?wqtpdz#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.ttf?wqtpdz') format('truetype'),
  url('../fonts/icomoon/icomoon.woff?wqtpdz') format('woff'),
  url('../fonts/icomoon/icomoon.svg?wqtpdz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Roast_Chicken:before {
  content: "\e900";
}
.icon-Salad:before {
  content: "\e901";
}
.icon-sandwich:before {
  content: "\e902";
}
.icon-Served_Meal:before {
  content: "\e903";
}
.icon-Soup:before {
  content: "\e904";
}
.icon-Spaghetti:before {
  content: "\e905";
}
.icon-Spoon_and_Fork_with_Knife:before {
  content: "\e906";
}
.icon-Steak:before {
  content: "\e907";
}
.icon-Steamed_Dumpling03:before {
  content: "\e908";
}
.icon-Sushi04:before {
  content: "\e909";
}
.icon-Water:before {
  content: "\e90a";
}
.icon-Sushi03:before {
  content: "\e90b";
}
.icon-Bakery:before {
  content: "\e90c";
}
.icon-Bread:before {
  content: "\e90d";
}
.icon-Burger:before {
  content: "\e90e";
}
.icon-Cake:before {
  content: "\e90f";
}
.icon-carrot:before {
  content: "\e910";
}
.icon-Cheese:before {
  content: "\e911";
}
.icon-Chef_Hat:before {
  content: "\e912";
}
.icon-Cocktail:before {
  content: "\e913";
}
.icon-Coffee01:before {
  content: "\e914";
}
.icon-Cupcake:before {
  content: "\e915";
}
.icon-Donut:before {
  content: "\e916";
}
.icon-Drink:before {
  content: "\e917";
}
.icon-Fish02:before {
  content: "\e918";
}
.icon-Frenchfries:before {
  content: "\e919";
}
.icon-Fried_Chicken:before {
  content: "\e91a";
}
.icon-Fried_Egg:before {
  content: "\e91b";
}
.icon-fruit:before {
  content: "\e91c";
}
.icon-Ice_Cream01:before {
  content: "\e91d";
}
.icon-kebab:before {
  content: "\e91e";
}
.icon-Ketchup:before {
  content: "\e91f";
}
.icon-Lollipop:before {
  content: "\e920";
}
.icon-Meat:before {
  content: "\e921";
}
.icon-Menu:before {
  content: "\e922";
}
.icon-Noodle:before {
  content: "\e923";
}
.icon-Pie:before {
  content: "\e924";
}
.icon-Pizza:before {
  content: "\e925";
}
.icon-Pot01:before {
  content: "\e926";
}
.icon-Prawn:before {
  content: "\e927";
}
.icon-Rice:before {
  content: "\e928";
}